// Styling for the navbar
// .fixed-top {
//   position: sticky;
// }
#mainNav {
  // position: absolute;
  position: sticky;
  border-bottom: 1px solid $gray-200;
  background-color: white;
  @include sans-serif-font;
  .navbar-brand {
    // font-weight: 800;
    color: $gray-800;
    // color: $theme-green;
    font-size: 34px;
  }
  .navbar-toggler {
    font-size: 12px;
    font-weight: 800;
    padding: 13px;
    text-transform: uppercase;
    color: $gray-800;
    // color: $theme-green;
  }
  .navbar-nav {
    > li.nav-item {
      > a {
        font-size: 14px;
        font-weight: 800;
        letter-spacing: 1px;
        // text-transform: uppercase;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    position: absolute;
    border-bottom: 1px solid transparent;
    background: transparent;
    .navbar-brand {
      padding: 10px 20px;
      color: $white;
      &:focus,
      &:hover {
        color: fade-out($white, .2);
      }
    }
    .navbar-nav {
      > li.nav-item {
        > a {
          padding: 10px 20px;
          color: $white;
          &:focus,
          &:hover {
            color: fade-out($white, .2);
          }
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    &.is-fixed {
      /* when the user scrolls down, we hide the header right above the viewport */
      position: fixed;
      // top: -67px;
      top: -88px;
      -webkit-transition: -webkit-transform 0.2s;
      -moz-transition: -moz-transform 0.2s;
      transition: transform 0.2s;
      border-bottom: 1px solid darken($white, .05);
      background-color: fade-out($white, .1);
      .navbar-brand {
        color: $gray-900;
        // color: $theme-green;
        &:focus,
        &:hover {
          // color: $primary;
          // color: $gray-900;
          color: $theme-green;
        }
      }
      .navbar-nav {
        > li.nav-item {
          > a {
            color: $gray-900;
            // color: $theme-green;
            &:focus,
            &:hover {
              // color: $primary;
              // color: $gray-900;
              color: $theme-green;
            }
          }
        }
      }
    }
    &.is-visible {
      /* if the user changes the scrolling direction, we show the header */
      -webkit-transform: translate3d(0, 100%, 0);
      -moz-transform: translate3d(0, 100%, 0);
      -ms-transform: translate3d(0, 100%, 0);
      -o-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      border-bottom: 1px solid #212529;
    }
  }
}
