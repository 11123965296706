//====================================
// Global Imports
//====================================
// $theme-green: #113d16;
$theme-green: #596b55;
/* Base Styles -------------------- */
@font-face {
  font-family: 'Playlist';
  src: url('../fonts/Playlist-Script.woff2') format('woff2'),
    url('../fonts/Playlist-Script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
// 1- Import bootstrap framework
@import '1-frameworks/index';
// 2 - Import plugins
@import '2-plugins/index';
// 3 - Import base
@import '3-base/index';
// 2 - Import modules
@import '4-modules/index';
// CUSTOM STYLES
body {
  opacity: 1;
  transition: 0.1s opacity;
}
body.fade-out {
  opacity: 0;
  transition: none;
}
// General Styles
.playlist-font {
  font-family: 'Playlist' !important;
  font-weight: normal;
  font-style: normal;
}
.text-theme {
  color: $theme-green;
}
a:focus,
a:hover {
  color: $theme-green;
}
.content-section {
  margin-top: 60px;
  margin-bottom: 60px;
}
// Button Styles
.btn-outline-primary {
  color: $theme-green;
  border-color: $theme-green;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: $theme-green;
  border-color: $theme-green;
}
.btn-link:hover {
  color: $theme-green;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $theme-green;
}
// Price Card Styles
.price-card-deck {
  .card-header {
    background-color: $theme-green;
    color: white;
    border-radius: 0px;
    // border-top-left-radius: 4px;
    // border-top-left-radius: 4px;
    border-radius: 0px;
    padding: 20px;
  }
  .card-title {
    font-size: 40px;
  }
  .card-body {
    // border: 1px solid $theme-green;
    border: none;
    // max-height: 500px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
// FAQ Accordion Styles
#faqAccordion {
  .card-header {
    background-color: white;
    border-radius: 0px;
  }
}
.accordion .fa {
  margin-right: 0.5rem;
}
// Testimonial Carousel Styles
#testimonial-carousel {
  .testimonial {
    width: 75%;
    margin: 0 auto;
  }
  .fa-angle-left,
  .fa-angle-right {
    color: $theme-green;
    font-size: 50px;
  }
  .fa-quote-left {
    margin-right: 10px;
  }
  .fa-quote-right {
    margin-left: 10px;
  }
}
.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}
// Footer Logo Styles
.footer-logo {
  max-width: 115px;
  // padding-top: 50px;
  padding-bottom: 50px;
}
.positive-logo {
  width: 100%;
  max-width: 300px;
  // padding-top: 50px;
  padding-bottom: 50px;
}
.sb_registration {
  max-width: 100% !important;
}
#schoolbox .accentBackgroundColor {
  background-color: $theme-green !important;
}
.table {
  th,
  td {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    // width: calc(100% / 5);
    width: calc(100% / 6);
  }
}
.small {
  font-size: 75%;
}